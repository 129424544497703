@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .nav-div-container {
    display: flex;
    justify-content: space-between;
    margin: 15px 40px;
    padding: 0;
    align-items: center;
} */

/* .logo {
   margin: 0;
   padding: 0;
} */

.logo-img {
    margin-top: 12px;
    width: 60px;
}

.list-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; 
    /* margin-right: 20px;  */
}

.nav-list {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3em;
    font-weight: 500;
    text-decoration: none;
    color: #2a2c37;
    margin: 10px 20px;
    cursor: pointer;
    position: relative;
}
.nav-list:last-child {
    margin-right: 0;
}

.hover-line {
    position : absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 0%;
    transition: width 0.4s linear;
}


.nav-list:hover .hover-line {
    background-color: #46fff6; 
    width: 24px;
}

.list-container .is-active {
    font-weight: 700;
    color: #7f00e0;

}

.text-under-name {
    font-family: 'Noto Sans', sans-serif;
    font-size: 2em;
    margin: 1% 0;
}
.main-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 24%;
    margin-top: 9%;    
}

.main-banner {
  width: 100%;
}

.text-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
    margin-top: 10%;
}


.my-name{
    font-family: 'Open Sans', sans-serif;
    font-size: 7.8em;
    margin: 0;
    margin-left: -3px;
    font-weight: 800;
    letter-spacing: 1px;

}


.text-wrapper h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 2.8em;
    margin: 0;
    color: #696b72;
}

.front-end {
    border-bottom: 2px solid black;
}

.text-wrapper h5{ 
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4em;
    font-weight: 400;
    margin-top: 7%;
}

.btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin-top: 2%;
}

button {
    margin: 10px;
    border-radius: 10px;
    font-size: 1.3em;
    font-weight: 500;
    background-color: white;
    color: black;
    cursor: pointer;
    border: none;
    border: 2px solid #7f00e0; 
    transition: all 0.4s ease;
    font-family: 'Heebo', sans-serif;
    letter-spacing: 0.5px;
    box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.1); 
    /* box-shadow: 3px 6px white, 3px 6px 1px 1px #7f00e0;  */
}  

.main-btns {
  width: 180px;
  height: 60px;

}


button .btnForEmail {
  text-decoration: none;
  color: black;
}

button:nth-child(1) {
   margin-left: 0;
}

button:focus { outline:0 !important; }

button:hover {
  box-shadow: 3px 7px 10px rgba(70, 255, 246, 0.9);
  background-color: #7f00e0;
  color: white;
  font-weight: 800;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}
  
button:hover .btnForEmail{
  color: white;
}

button:active {
    -webkit-transform: scale(.98);
            transform: scale(.98);
}



@media (max-width: 1000px) {
  .my-name {
    font-size: 5.6em;
  }
  .text-wrapper h3 {
    font-size: 2em;
  }
   .text-wrapper h5 {
    font-size: 0.8em;
  }

}

@media (max-width: 480px) {

  .main-container {
    margin: 10%;
    padding-top: 10%;
    height: 64vh;
  }
  .my-name {
    font-size: 3em;
  }

  .text-wrapper {
    margin-top: 0;
  }

   .text-wrapper h3 {
    font-size: 1.6em;
  }

  .text-wrapper h5 {
    font-size: 1em;
  }

    .main-btns {
    width: 130px;
    font-size: 1em;
  }
}


.about-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 7% 10%;
}

.about-me-text-container {
    text-align: left;
    margin: 0 auto;
}

.about-me {
    margin: 0 auto;
    width: 74%;

}
.about-me h1 {
    text-align: left;
}


.profile-top {
    margin: 0 auto;
    height: 350px;
    padding: 0 6% 4% 6%;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.profile-card-text {
    text-align: left;
}
.profile-card-text h1 {
    font-size: 2.4em;
    letter-spacing: 1px;  
    margin-top: 0;
}
.profile-card-text p {
    margin: 10px 0;
}

.profile-box {
    position: absolute;
    margin: 0 auto;
    width: 620px;
    height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #e7e2ff;
    border-radius: 14px;
    -webkit-align-items: center;
            align-items: center;
    border: 4px solid white;
    box-shadow:  4px 6px 9px #adadb2;

}

.profile-contact-btn {
    position: relative;
    width: 200px;
    height: 54px;
    bottom: -274px;
    left: 175px;
    background-color: #6034e5;
    border-radius: 30px;
    border: 3px solid white;
    display: -webkit-flex;
    display: flex;
    box-shadow:  4px 6px 9px #adadb2; 

    
}

.profile-btn-text {
    margin: auto;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 1.2em;
    letter-spacing: 1.2px;
    text-shadow: 1px 1px #232226;
}

.btnForEmail {
    text-decoration: none;
}
.profile-contact-btn:active {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
}


.profile-pic {
    width: 220px;
    height: 220px;
    border-radius: 50%;  
    border: 6px solid white;
    margin-right: 4.5%;
}


.about-me-text-container {
    width: 74%;
}

.about-me-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.7em;
    margin: 24px 0;
    color: #2a2a2b;
    letter-spacing: 0.2px;
}

.about-me-text span {
    display: -webkit-flex;
    display: flex;
}

.about-me-text.tech {
    margin-bottom: 0;
}

.tech-list {
    margin: 20px;
    padding-left: 4px;
}
li.tech-use {
    list-style: none;
    font-weight: 500;
    font-size: 1.6em;
    line-height: 1.6em;

}

.laptop-img, .explore-img {
    width: 360px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
    padding: 5%;
}


@media (max-width: 1000px) { 
    .profile-top {
        margin-top: 6%;
        height: 630px;
        padding: 0;
    }
    .profile-box {
        width: 340px;
        height: 540px;
    }
    .profile-box {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .profile-pic {
        margin: 0;
    }
    .profile-card-text {
        margin-top: 10%;
    }

    .profile-contact-btn {
        bottom: -520px;
        left: 0;
    }

    .about-me-text-container {
    width: 90%;
}

    .laptop-img, .explore-img {
        width: 260px;
    }
}


@media (max-width: 480px) {
    .about-me {
        margin: 0;
    }
    .about-me-text-container {
        width: 100%;
   
    }
    .about-me-text-container p {
        font-size: 1em;
    }
    li.tech-use {
        font-size: 1.1em;
    }
}
.project-cards {
    font-family: 'Heebo', sans-serif;
    display: -webkit-flex;
    display: flex;
    margin: 10% 0;
    vertical-align: text-top;
}

.project-cards:nth-child(1) {
    margin-top: 2%;
}

.project-preview {
    width: 650px;
    margin-right: 10%;
}
.project-preview img {
    width: 100%;
    object-fit: cover;
    /* overflow: hidden; */
}

.project-info {
    width: 100%;  
}

.project-title {
    font-size: 2em;
    margin-top: -11px;
}

.project-description {
    font-size: 1.2em;
}

.project-tech {
    font-size: 1.3em;
    font-weight: 500;
}

.see-project-btns {
    margin: 6px;
    width: 68px;
    height: 34px;
    font-size: 1.2em; 
    border-radius: 20px;
    
    /* color: black; */
    /* border: 2px solid #7f00e0; */
}

.btn-text {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    text-decoration: none;
    font-size: 0.8em;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 0;
    color: black;
}

.see-project-btns:hover .btn-text {
    color: white;
}

@media (max-width: 1000px) {
    .project-cards {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-bottom: 45px;
    }
    .project-preview {
        width: 100%;
        margin-right: 0;
    }

    .project-info h1 {
        margin-top: 5%;
    }
}

@media (max-width: 480px) {
    .project-title {
        font-size: 1.5em;
    }
}


body {
  padding: 0;
  margin: 0;
}

