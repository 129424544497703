@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24%;
    margin-top: 9%;    
}

.main-banner {
  width: 100%;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 10%;
}


.my-name{
    font-family: 'Open Sans', sans-serif;
    font-size: 7.8em;
    margin: 0;
    margin-left: -3px;
    font-weight: 800;
    letter-spacing: 1px;

}


.text-wrapper h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 2.8em;
    margin: 0;
    color: #696b72;
}

.front-end {
    border-bottom: 2px solid black;
}

.text-wrapper h5{ 
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4em;
    font-weight: 400;
    margin-top: 7%;
}

.btn-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 2%;
}

button {
    margin: 10px;
    border-radius: 10px;
    font-size: 1.3em;
    font-weight: 500;
    background-color: white;
    color: black;
    cursor: pointer;
    border: none;
    border: 2px solid #7f00e0; 
    transition: all 0.4s ease;
    font-family: 'Heebo', sans-serif;
    letter-spacing: 0.5px;
    box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.1); 
    /* box-shadow: 3px 6px white, 3px 6px 1px 1px #7f00e0;  */
}  

.main-btns {
  width: 180px;
  height: 60px;

}


button .btnForEmail {
  text-decoration: none;
  color: black;
}

button:nth-child(1) {
   margin-left: 0;
}

button:focus { outline:0 !important; }

button:hover {
  box-shadow: 3px 7px 10px rgba(70, 255, 246, 0.9);
  background-color: #7f00e0;
  color: white;
  font-weight: 800;
  transform: translateY(-4px);
}
  
button:hover .btnForEmail{
  color: white;
}

button:active {
    transform: scale(.98);
}



@media (max-width: 1000px) {
  .my-name {
    font-size: 5.6em;
  }
  .text-wrapper h3 {
    font-size: 2em;
  }
   .text-wrapper h5 {
    font-size: 0.8em;
  }

}

@media (max-width: 480px) {

  .main-container {
    margin: 10%;
    padding-top: 10%;
    height: 64vh;
  }
  .my-name {
    font-size: 3em;
  }

  .text-wrapper {
    margin-top: 0;
  }

   .text-wrapper h3 {
    font-size: 1.6em;
  }

  .text-wrapper h5 {
    font-size: 1em;
  }

    .main-btns {
    width: 130px;
    font-size: 1em;
  }
}

