@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.about-container {
    display: flex;
    flex-direction: column;
    margin: 7% 10%;
}

.about-me-text-container {
    text-align: left;
    margin: 0 auto;
}

.about-me {
    margin: 0 auto;
    width: 74%;

}
.about-me h1 {
    text-align: left;
}


.profile-top {
    margin: 0 auto;
    height: 350px;
    padding: 0 6% 4% 6%;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.profile-card-text {
    text-align: left;
}
.profile-card-text h1 {
    font-size: 2.4em;
    letter-spacing: 1px;  
    margin-top: 0;
}
.profile-card-text p {
    margin: 10px 0;
}

.profile-box {
    position: absolute;
    margin: 0 auto;
    width: 620px;
    height: 300px;
    display: flex;
    justify-content: center;
    background-color: #e7e2ff;
    border-radius: 14px;
    align-items: center;
    border: 4px solid white;
    box-shadow:  4px 6px 9px #adadb2;

}

.profile-contact-btn {
    position: relative;
    width: 200px;
    height: 54px;
    bottom: -274px;
    left: 175px;
    background-color: #6034e5;
    border-radius: 30px;
    border: 3px solid white;
    display: flex;
    box-shadow:  4px 6px 9px #adadb2; 

    
}

.profile-btn-text {
    margin: auto;
    align-items: center;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 1.2em;
    letter-spacing: 1.2px;
    text-shadow: 1px 1px #232226;
}

.btnForEmail {
    text-decoration: none;
}
.profile-contact-btn:active {
    transform: scale(0.98);
}


.profile-pic {
    width: 220px;
    height: 220px;
    border-radius: 50%;  
    border: 6px solid white;
    margin-right: 4.5%;
}


.about-me-text-container {
    width: 74%;
}

.about-me-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.7em;
    margin: 24px 0;
    color: #2a2a2b;
    letter-spacing: 0.2px;
}

.about-me-text span {
    display: flex;
}

.about-me-text.tech {
    margin-bottom: 0;
}

.tech-list {
    margin: 20px;
    padding-left: 4px;
}
li.tech-use {
    list-style: none;
    font-weight: 500;
    font-size: 1.6em;
    line-height: 1.6em;

}

.laptop-img, .explore-img {
    width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5%;
}


@media (max-width: 1000px) { 
    .profile-top {
        margin-top: 6%;
        height: 630px;
        padding: 0;
    }
    .profile-box {
        width: 340px;
        height: 540px;
    }
    .profile-box {
        display: flex;
        flex-direction: column;
    }
    .profile-pic {
        margin: 0;
    }
    .profile-card-text {
        margin-top: 10%;
    }

    .profile-contact-btn {
        bottom: -520px;
        left: 0;
    }

    .about-me-text-container {
    width: 90%;
}

    .laptop-img, .explore-img {
        width: 260px;
    }
}


@media (max-width: 480px) {
    .about-me {
        margin: 0;
    }
    .about-me-text-container {
        width: 100%;
   
    }
    .about-me-text-container p {
        font-size: 1em;
    }
    li.tech-use {
        font-size: 1.1em;
    }
}