@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* .nav-div-container {
    display: flex;
    justify-content: space-between;
    margin: 15px 40px;
    padding: 0;
    align-items: center;
} */

/* .logo {
   margin: 0;
   padding: 0;
} */

.logo-img {
    margin-top: 12px;
    width: 60px;
}

.list-container {
    display: flex;
    justify-content: flex-end; 
    /* margin-right: 20px;  */
}

.nav-list {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3em;
    font-weight: 500;
    text-decoration: none;
    color: #2a2c37;
    margin: 10px 20px;
    cursor: pointer;
    position: relative;
}
.nav-list:last-child {
    margin-right: 0;
}

.hover-line {
    position : absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 0%;
    -webkit-transition: width 0.4s linear;
    transition: width 0.4s linear;
}


.nav-list:hover .hover-line {
    background-color: #46fff6; 
    width: 24px;
}

.list-container .is-active {
    font-weight: 700;
    color: #7f00e0;

}

.text-under-name {
    font-family: 'Noto Sans', sans-serif;
    font-size: 2em;
    margin: 1% 0;
}