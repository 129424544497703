@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');


.project-cards {
    font-family: 'Heebo', sans-serif;
    display: flex;
    margin: 10% 0;
    vertical-align: text-top;
}

.project-cards:nth-child(1) {
    margin-top: 2%;
}

.project-preview {
    width: 650px;
    margin-right: 10%;
}
.project-preview img {
    width: 100%;
    object-fit: cover;
    /* overflow: hidden; */
}

.project-info {
    width: 100%;  
}

.project-title {
    font-size: 2em;
    margin-top: -11px;
}

.project-description {
    font-size: 1.2em;
}

.project-tech {
    font-size: 1.3em;
    font-weight: 500;
}

.see-project-btns {
    margin: 6px;
    width: 68px;
    height: 34px;
    font-size: 1.2em; 
    border-radius: 20px;
    
    /* color: black; */
    /* border: 2px solid #7f00e0; */
}

.btn-text {
    display: flex;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    font-size: 0.8em;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 0;
    color: black;
}

.see-project-btns:hover .btn-text {
    color: white;
}

@media (max-width: 1000px) {
    .project-cards {
        flex-direction: column;
        margin-bottom: 45px;
    }
    .project-preview {
        width: 100%;
        margin-right: 0;
    }

    .project-info h1 {
        margin-top: 5%;
    }
}

@media (max-width: 480px) {
    .project-title {
        font-size: 1.5em;
    }
}

